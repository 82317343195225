import invest from '../../../assets/main/imgs/Лупа иконка.svg';
import stat from '../../../assets/main/imgs/График иконка.svg';
import data from '../../../assets/main/imgs/Восклицательный знак иконка.svg';
import QuestionIcon from '../../../assets/main/imgs/icon-questions.svg';
import PollsIcon from '../../../assets/main/imgs/icon-polls.svg';
import VotingIcon from '../../../assets/main/imgs/icon-voting.svg';


const Analytics = () => {

	return (
		<div>
			<div className="mobile:pt-[170px] tablet:pt-[340px] laptop:pt-[450px]  desktop:pt-[600px] flex mobile:flex-col mobile:gap-y-[85px] laptop:flex-row laptop:justify-between laptop:gap-x-[158px] desktop:gap-x-12 text-white ">

				<div className="flex flex-col laptop:w-[600px] desktop:w-[800px] ">

					<span className="analytics-title ">
						Добавляйте аналитику в свою презентацию
					</span>

					<span className="analytics-descr">
						Визуальная инфомарция (диаграммы, цифры и т.д.) воспринимается
						легче, чем текст. Добавляйте проверенные данные, собранные
						Аналитическим центром НАФИ в презентацию одним кликом.
					</span>

					<div className="text-left">

						<div className="flex items-center  mobile:gap-x-[22px] tablet:gap-x-10 desktop:justify-between">

							<div className="analytics-bloks">
								<img src={invest} alt="diagram" className="analytics-icon" />
								<span className="analytics-icon-text">Открытые исследования</span>
							</div>

							<div className="analytics-bloks">
								<img src={stat} alt="diagram" className="analytics-icon" />
								<span className="analytics-icon-text">Собранная логистика</span>
							</div>

							<div className="analytics-bloks">
								<img src={data} alt="diagram" className="analytics-icon" />
								<span className="analytics-icon-text">Достоверные сведения</span>
							</div>

						</div>
						<button className="analtics-btn mobile:hidden laptop:block">
							Заказать
						</button>
					</div>
				</div>

				<div className="desktop:flex  text-white laptop:pt-[435px] laptop:w-[600px] desktop:w-[800px]">

					<div className="flex flex-col justify-between ">
						<span className="analytics-title">
							Публичное выступление - площадка для сбора данных
						</span>
						<span className="analytics-descr">
							Используйте интерактивные слайды для вовлечения аудитории и получения мгновенного отклика.
						</span>

						<div className="text-right">
							<div className="flex items-center mobile:gap-x-[22px] tablet:gap-x-10 justify-between">

								<div className="analytics-bloks">
									<img src={PollsIcon} alt="diagram" className="analytics-icon" />
									<span className="analytics-icon-text">Опросы</span>
								</div>

								<div className="analytics-bloks">
									<img src={VotingIcon} alt="diagram" className="analytics-icon" />
									<span className="analytics-icon-text">Голосования</span>
								</div>

								<div className="analytics-bloks">
									<img src={QuestionIcon} alt="diagram" className="analytics-icon" />
									<span className="analytics-icon-text">Вопросы</span>
								</div>
							</div>
							<button className="analtics-btn  mobile:hidden laptop:block">
								начать сейчас
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Analytics;
