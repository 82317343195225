// const src = "https://www.youtube.com/embed/eZ2KFYR13yE?si=rLJqH0YpeGZ2Pi7e";
import videoIcon from '../../assets/reviews/videoIcon.png'

const Video = ({ areCardsExpanded }) => {
  return (
    <div 
    
    className= {`${areCardsExpanded ? "hidden" : "mobile:absolute mobile:ml-[1.3rem] mobile:mt-[3000px] tablet:relative mobile:rounded-none mobile:w-[320px]  mobile:h-[426px]tablet:rounded-xl laptop:rounded-xl desktop:rounded-xl desktop:w-full desktop:mt-[100px] desktop:relative desktop:mb-28 laptop:relative laptop:w-full laptop:m-0 laptop:h-[571px] tablet:h-[756px] tablet:w-[688px] tablet:m-10 m  border-4 border-white rounded-2xl flex flex-col items-center justify-center  bg-bg-darkPurple relative z-40"}`}>
      <img src={videoIcon} alt="video icon" className="mb-20 mobile:w-[171px]"></img>
      <span className="text-white text-[36px] mobile:text-[30px] mobile:text-left mobile:ml-7 mobile:-mt-12 mobile:pb-8 font-medium text-center font-fututraFont">
        Здесь будет мини-инструкция , как <br /> работает конструктор
      </span>
    </div>
  );
};

export default Video;
