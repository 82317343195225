import CarouselItem from "./CarouselItem";
import secondBg from "../../../assets/main/imgs/block2_bg.png";



const Carousel = () => {

	return <>
		<span className="mobile:text-3xl tablet:mr-auto laptop:text-[28px] laptop:mb-10 desktop:text-left desktop:text-[60px]  relative z-10">
			От идеи до выступления - один сервис
		</span>
		

		<CarouselItem />
		

		<div>
			<img
				src={secondBg}
				alt="backgroundImg"
				className="laptop:bg-cover absolute left-0 z-0 w-[100%] "
			/>
		</div>

		<div className="">
			<div className="absolute w-[100%] h-48 left-0 z-20  bg-gradient-to-r from-purpleShadow from-40% via-brownShadow via-75% to-cardBtnPurple to-99% filter blur-[120px] opacity-50 rounded-full"></div>
		</div>
	</>
}

export default Carousel

