import React, { useEffect } from "react";

const NextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    
    <div className=" max-h-11 mobile:hidden tablet:block laptop:block tablet:absolute laptop:absolute" style={{
      position: "absolute",
      top: "-100px",       
      right: "20%",       
      pointerEvents: "none", 
      zIndex: 1000,
    }}>
      <button
        className={"custom-next"}
        style={{
          ...style,
          pointerEvents: "auto", 
          fontSize: "46px",
          color: "white",
        }}
        onClick={onClick}
      >
        <span>&gt;&gt;</span>
      </button>

    </div>
  );
};

export default NextArrow;
