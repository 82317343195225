import React, { useState } from "react";

const ReviewsCard = ({ review }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      <div className=" mobile:w-[320px] font-fututraFont tablet:w-[350px] laptop:w-[400px] laptop:min-h-[281px] tablet:-ml-[68px] laptop:ml-0 desktop:w-[400px] desktop:mr-0 laptop:mr-2  border-cardBorder border bg-gradient-to-t from-cardGradient to-bg-darkPurple rounded-tl-xl rounded-tr-xl rounded-br-xl desktop:pt-[30px] desktop:pr-[40px] desktop:pb-[20px] desktop:pl-[30px] laptop:pt-[30px] laptop:pr-[40px] laptop:pb-[20px] laptop:pl-[30px] tablet:pt-[30px] tablet:pr-[40px] tablet:pb-[20px] tablet:pl-[30px]  mobile:pr-[25px] mobile:pb-[25px] mobile:pl-[10px] relative z-20">
        <p className="mt-5 text-white font-light laptop:text-[20px] desktop-[24px] min-h-20">
          {isExpanded ? review.text : `${review.text.slice(0, 95)}...`}
        </p>
        <button
          className="mb-5 mt-5"
          onClick={() => setIsExpanded((prev) => !prev)}
        >
          <span className="text-white underline laptop:text-[18px] desktop-[24px] font-light">
            {isExpanded ? "Скрыть комментарий" : "Посмотреть весь комментарий"}
          </span>
        </button>
        <div className="flex items-center gap-4 mt-auto">
          <img
            src={review.avatar}
            alt="profile pic"
            className="w-[50px] h-[50px]"
          />
          <span className="text-white laptop:text-[20px] font-normal">
            {review.author}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReviewsCard;
