
import template from '../../../assets/main/imgs/icon_template.svg';
import brand from '../../../assets/main/imgs/icon_brand.svg';
import interactive from '../../../assets/main/imgs/icon_interactive.svg';

const Constructor = () => {

	return (
		
		<div className="mobile:pt-[85px] flex mobile:flex-col  laptop:flex-row laptop:justify-between laptop:w-[475px] desktop:w-[700px] text-white ">
			<div className="desktop:flex desktop:flex-col laptop:mt-0">
				<span className="analytics-title">
					Конструктор презентаций
				</span>
				<span className="analytics-descr">
					Оформляйте информацию в брендированные слайды быстро и просто.

				</span>

				<div>

					<div className="flex items-center  mobile:gap-x-[22px] tablet:gap-x-10 tablet:justify-between desktop:justify-between">

						<div className="analytics-bloks mobile:w-24">
							<img src={template} alt="diagram" className="analytics-icon" />
							<span className="analytics-icon-text">Готовые шаблоны</span>

						</div>

						<div className="analytics-bloks  mobile:w-24">
							<img src={brand} alt="diagram" className="analytics-icon" />
							<span className="analytics-icon-text">Фирменный бренд</span>
						</div>

						<div className="analytics-bloks  mobile:w-24">
							<img src={interactive} alt="diagram" className="analytics-icon" />
							<span className="analytics-icon-text">Интерактивы и аналитика</span>

						</div>
					</div>
					<button className=" mobile:w-[320px] mobile:h-9 mobile:rounded-2xl mobile:text-xl mobile:mt-[58px] tablet:hidden laptop:block laptop:w-[297px] laptop:h-[50px] laptop:rounded-[10px] laptop:text-[22px] desktop:w-[385px] desktop:h-[76px] desktop:rounded-[30px] desktop:text-2xl  analtics-btn">
						Попробовать
					</button>
				</div>

			</div>
		</div>
	)

}



export default Constructor;
