import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import tariffsData from "./tariffsData";
import mark from '../../assets/tariffs/mark.svg'
import eternityMark from '../../assets/tariffs/eternityMark.svg'
import logo from '../../assets/tariffs/mobile_logo.png'
import closeIcon from '../../assets/tariffs/closeIcon.svg'

  const Tariffs = ({ tariffs, prevArrow, tariffNextArrow, areCardsExpanded, setAreCardsExpanded }) => {
  const [isActive, setIsActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [screenType, setScreenType] = useState("mobile");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      console.log('isActive:', isActive);
      console.log('isExpanded:', isExpanded);
      console.log('screenType:', screenType);
      console.log("Current window width:", width);
      setIsActive(width <= 768);
      setScreenType(width <= 684 ? "mobile" : width <= 768 ? "tablet" : width < 1280 ? "laptop" : "desktop");
    };
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleView = () => {
    console.log("Toggle view called", screenType);
     if (screenType === "mobile") {
      if (screenType === "mobile") {
        setAreCardsExpanded((prev) => {
          console.log("Previous state:", prev);
          return !prev;
        });
    }
  }
}

  const settings = {
    dots: false,
    prevArrow: prevArrow,
    nextArrow: tariffNextArrow,
    infinite: true,
    speed: 500,
    slidesToShow: isActive ? 1 : 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          centerPadding: "20px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 360,
        settings: "unslick",
      },
    ],
  };

  console.log(tariffs);
  return (
    <div className="laptop:flex laptop:flex-col font-fututraFont font-normal desktop:relative desktop:z-20 tablet:ml-[10px] laptop:ml-[70px] laptop:mt-20 desktop:flex desktop:flex-col desktop:items-center desktop:justify-center">
      { areCardsExpanded  && screenType === 'mobile' ? <div className="hidden"></div> : <div className="h-[380px] laptop:w-[1250px] mobile:w-[330px] desktop:w-[1890px] laptop:-ml-[60px] rounded-t-full rounded-b-full rounded-l-full bg-turquoiseShadow opacity-20 blur-2xl rotate-6 z-1 -mt-[250px] tablet:w-[707px]"></div>}


      {areCardsExpanded  && screenType === 'mobile' ? <nav className="w-[320px] h-[32px] flex m-5"><img src={logo} alt="logo" className="mr-auto"></img><button onClick={toggleView}><img src={closeIcon} alt="logo"></img></button> </nav> : null}
      <div className="bg-bg-darkPurple desktop:max-w-[1568px] laptop:max-w-[1110px] mobile:ml-3 tablet:-ml-[5px] mobile:-mt-28">
        <div className="laptop:flex tablet:ml-[40px]">
          {areCardsExpanded && screenType === 'mobile' ? <h1 className=" text-white text-[18px] mt-36 mr-auto font-light mobile:ml-[20px] tablet:mb-7 desktop:-ml-14">
            Тарифные планы
          </h1> : <h1 className="text-white text-[40px] mr-auto font-light mobile:ml-[20px] tablet:mb-7 desktop:-ml-14">Тарифы</h1> }
            <Link to="/comparing" target="_blank">
          <button
            onClick={toggleView}
            className={`${areCardsExpanded && screenType === 'mobile' ? "hidden" : "cursor-pointer text-white text-3xl opacity-60 hover:opacity-100 mobile:ml-5 mobile:text-[24px] tablet:absolute tablet:ml-[220px] tablet:mt-[880px] mobile:absolute mobile:mt-[2950px] mobile:whitespace-nowrap mobile:text-center laptop:relative laptop:mt-0 desktop:relative desktop:mt-0 mr-[0px] mb-[0px] ml-[250px] z-20"}`}
          >
            Сравнить тарифы &gt;&gt;
          </button>
            </Link>
        </div>
      <>
      { areCardsExpanded && screenType === "mobile" ? (
  <div className="laptop:flex">
    <div className="slider-container-1 mobile:ml-2 w-[320px] laptop:w-[356px] desktop:w-[418px] desktop:mr-[95px] laptop:m-[15px] bg-gradient-to-b from-cardGradient to-bg-darkPurple rounded-[20px] mt-10 mr-10 mb-10 border-cardBorder border flex flex-col items-center  p-[30px] relative z-30">
    <h1 className="text-3xl text-center font-normal text-white">
      Начальный
    </h1>
    <div className="flex flex-col items-center mt-4 mb-4">
            <span className="text-xl text-white pt-0 pb-4">
            0 ₽ / месяц
            </span>
            <button className="w-[270px] h-[50px] rounded-[7px] bg-cardBtnPurple cursor-pointer">
              <span className="text-white text-[18px]">Выбрать</span>
            </button>
          </div>
        <div className="">
          <div>
            <span className="text-cardBtnOrange text-lg mb-3">Основное</span>
            <div className="flex text-white text-sm gap-14 mt-4">
              <span className="w-44 mr-auto">Количество презентаций на аккаунте</span>
              <span className="whitespace-nowrap">до 3</span>
            </div>
            <div className="flex text-white text-sm mt-4">
              <span className="w-44 mr-auto">Количество слайдов в одной презентации</span>
              <span className="whitespace-nowrap">до 5</span>
            </div>
            <div className="flex text-white text-sm mt-4">
              <span className="w-44 mr-auto">Экспорт презентации в PDF, PNG</span>
              <img src={mark} alt="icon" className="w-5"></img>
            </div>
          </div>
          <div className="mt-5">
            <span className="text-cardBtnOrange text-lg mb-3">Интерактивы</span>
            <div className="flex text-white text-sm gap-14 mt-4">
              <span className="w-44 mr-auto">Количество слайдов с интерактивными элементами в презентации</span>
              <span className="whitespace-nowrap">1</span>
            </div>
            <div className="flex text-white text-sm mt-4 justify-center items-center">
              <span className="w-44 mr-auto">Функция "мультиопрос" несколько вопросов на одном слайде</span>
              <hr className="h-[2px] text-white w-4"></hr>
            </div>
            <div className="flex text-white text-sm mt-4 ustify-center items-center">
              <span className="w-44 mr-auto">Количество участников опросов</span>
              <img src={eternityMark} alt="icon" className="w-5"></img>
            </div>
            <div className="flex text-white text-sm mt-4 justify-center items-center">
              <span className="w-44 mr-auto">Возможность встраивания кода опроса на свой сайт</span>
              <hr className="h-[2px] text-white w-4"></hr>
            </div>
          </div>
          <div className="mt-5">
            <span className="text-cardBtnOrange text-lg mb-3">Дизайн</span>
            <div className="flex text-white text-sm mt-4 ustify-center items-center">
              <span className="w-44 mr-auto">Конструктор презентаций — стандартные шаблоны</span>
              <img src={mark} alt="icon" className="w-5"></img>
            </div>
            <div className="flex text-white text-sm mt-4 justify-center items-center">
              <span className="w-44 mr-auto">Брендирование</span>
              <hr className="h-[2px] text-white w-4"></hr>
            </div>
          </div>
          <div className="mt-5">
            <span className="text-cardBtnOrange text-lg mb-3">Дополнительно</span>
            <div className="flex text-white text-sm mt-4 ustify-center items-center">
              <span className="w-44 mr-auto">Возможность использовать открытые аналитические данные от НАФИ</span>
              <hr className="h-[2px] text-white w-4"></hr>

            </div>
            <div className="flex text-white text-sm mt-4 justify-center items-center">
              <span className="w-44 mr-auto">Экспорт результатов опросов  в Excel, PDF</span>
              <img src={mark} alt="icon" className="w-5"></img>
            </div>
            <div className="flex text-white text-sm mt-4 justify-center items-center">
              <span className="w-44 mr-auto">Оплата от юридических лиц</span>
              <hr className="h-[2px] text-white w-4"></hr>
            </div>
          </div>
        </div>
    </div>
    <div className="slider-container-1 mobile:ml-2 w-[320px] laptop:w-[356px] desktop:w-[418px] desktop:mr-[95px] laptop:m-[15px] bg-gradient-to-b from-cardGradient to-bg-darkPurple rounded-[20px] mt-10 mr-10 mb-10 border-cardBorder border flex flex-col items-center  p-[30px] relative z-30">
    <h1 className="text-3xl text-center font-normal text-white">
      Базовый
    </h1>
    <div className="flex flex-col items-center mt-4 mb-4">
            <span className="text-xl text-white pt-0 pb-4">
            550 ₽ / месяц
            </span>
            <button className="w-[270px] h-[50px] rounded-[7px] bg-cardBtnPurple cursor-pointer">
              <span className="text-white text-[18px]">Выбрать</span>
            </button>
          </div>
        <div className="">
          <div>
            <span className="text-cardBtnOrange text-lg mb-3">Основное</span>
            <div className="flex text-white text-sm gap-14 mt-4">
              <span className="w-44 mr-auto">Количество презентаций на аккаунте</span>
              <span className="whitespace-nowrap">до 5</span>
            </div>
            <div className="flex text-white text-sm mt-4">
              <span className="w-44 mr-auto">Количество слайдов в одной презентации</span>
              <span className="whitespace-nowrap">до 10</span>
            </div>
            <div className="flex text-white text-sm mt-4">
              <span className="w-44 mr-auto">Экспорт презентации в PDF, PNG</span>
              <img src={mark} alt="icon" className="w-5"></img>
            </div>
          </div>
          <div className="mt-5">
            <span className="text-cardBtnOrange text-lg mb-3">Интерактивы</span>
            <div className="flex text-white text-sm gap-14 mt-4">
              <span className="w-44 mr-auto">Количество слайдов с интерактивными элементами в презентации</span>
              <span className="whitespace-nowrap">до 3</span>
            </div>
            <div className="flex text-white text-sm mt-4 justify-center items-center">
              <span className="w-44 mr-auto">Функция "мультиопрос" несколько вопросов на одном слайде</span>
              <hr className="h-[2px] text-white w-4"></hr>
            </div>
            <div className="flex text-white text-sm mt-4 ustify-center items-center">
              <span className="w-44 mr-auto">Количество участников опросов</span>
              <img src={eternityMark} alt="icon" className="w-5"></img>
            </div>
            <div className="flex text-white text-sm mt-4 justify-center items-center">
              <span className="w-44 mr-auto">Возможность встраивания кода опроса на свой сайт</span>
              <span className="whitespace-nowrap">до 3</span>
            </div>
          </div>
          <div className="mt-5">
            <span className="text-cardBtnOrange text-lg mb-3">Дизайн</span>
            <div className="flex text-white text-sm mt-4 ustify-center items-center">
              <span className="w-44 mr-auto">Конструктор презентаций — стандартные шаблоны</span>
              <img src={mark} alt="icon" className="w-5"></img>
            </div>
            <div className="flex text-white text-sm mt-4 justify-center items-center">
              <span className="w-44 mr-auto">Брендирование</span>
              <img src={mark} alt="icon" className="w-5"></img>
            </div>
          </div>
          <div className="mt-5">
            <span className="text-cardBtnOrange text-lg mb-3">Дополнительно</span>
            <div className="flex text-white text-sm mt-4 ustify-center items-center">
              <span className="w-44 mr-auto">Возможность использовать открытые аналитические данные от НАФИ</span>
              <img src={mark} alt="icon" className="w-5"></img>

            </div>
            <div className="flex text-white text-sm mt-4 justify-center items-center">
              <span className="w-44 mr-auto">Экспорт результатов опросов  в Excel, PDF</span>
              <img src={mark} alt="icon" className="w-5"></img>
            </div>
            <div className="flex text-white text-sm mt-4 justify-center items-center">
              <span className="w-44 mr-auto">Оплата от юридических лиц</span>
              <img src={mark} alt="icon" className="w-5"></img>
            </div>
          </div>
        </div>
    </div>
    <div className="slider-container-1 mobile:ml-2 w-[320px] laptop:w-[356px] desktop:w-[418px] desktop:mr-[95px] laptop:m-[15px] bg-gradient-to-b from-cardGradient to-bg-darkPurple rounded-[20px] mt-10 mr-10 mb-10 border-cardBorder border flex flex-col items-center  p-[30px] relative z-30">
    <h1 className="text-3xl text-center font-normal text-white">
      Премиум
    </h1>
    <div className="flex flex-col items-center mt-4 mb-4">
            <span className="text-xl text-white pt-0 pb-4">
            1120 ₽ / месяц
            </span>
            <button className="w-[270px] h-[50px] rounded-[7px] bg-cardBtnPurple cursor-pointer">
              <span className="text-white text-[18px]">Выбрать</span>
            </button>
          </div>
        <div className="">
          <div>
            <span className="text-cardBtnOrange text-lg mb-3">Основное</span>
            <div className="flex text-white text-sm gap-14 mt-4">
              <span className="w-44 mr-auto">Количество презентаций на аккаунте</span>
              <span className="whitespace-nowrap">до 20</span>
            </div>
            <div className="flex text-white text-sm mt-4">
              <span className="w-44 mr-auto">Количество слайдов в одной презентации</span>
              <span className="whitespace-nowrap">до 50</span>
            </div>
            <div className="flex text-white text-sm mt-4">
              <span className="w-44 mr-auto">Экспорт презентации в PDF, PNG</span>
              <img src={mark} alt="icon" className="w-5"></img>
            </div>
          </div>
          <div className="mt-5">
            <span className="text-cardBtnOrange text-lg mb-3">Интерактивы</span>
            <div className="flex text-white text-sm gap-14 mt-4">
              <span className="w-44 mr-auto">Количество слайдов с интерактивными элементами в презентации</span>
              <span className="whitespace-nowrap">до 10</span>
            </div>
            <div className="flex text-white text-sm mt-4 justify-center items-center">
              <span className="w-44 mr-auto">Функция "мультиопрос" несколько вопросов на одном слайде</span>
              <img src={mark} alt="icon" className="w-5"></img>
            </div>
            <div className="flex text-white text-sm mt-4 ustify-center items-center">
              <span className="w-44 mr-auto">Количество участников опросов</span>
              <img src={eternityMark} alt="icon" className="w-5"></img>
            </div>
            <div className="flex text-white text-sm mt-4 justify-center items-center">
              <span className="w-44 mr-auto">Возможность встраивания кода опроса на свой сайт</span>
              <span className="whitespace-nowrap">до 10</span>
            </div>
          </div>
          <div className="mt-5">
            <span className="text-cardBtnOrange text-lg mb-3">Дизайн</span>
            <div className="flex text-white text-sm mt-4 ustify-center items-center">
              <span className="w-44 mr-auto">Конструктор презентаций — стандартные шаблоны</span>
              <img src={mark} alt="icon" className="w-5"></img>
            </div>
            <div className="flex text-white text-sm mt-4 justify-center items-center">
              <span className="w-44 mr-auto">Брендирование</span>
              <img src={mark} alt="icon" className="w-5"></img>
            </div>
          </div>
          <div className="mt-5">
            <span className="text-cardBtnOrange text-lg mb-3">Дополнительно</span>
            <div className="flex text-white text-sm mt-4 ustify-center items-center">
              <span className="w-44 mr-auto">Возможность использовать открытые аналитические данные от НАФИ</span>
              <img src={mark} alt="icon" className="w-5"></img>

            </div>
            <div className="flex text-white text-sm mt-4 justify-center items-center">
              <span className="w-44 mr-auto">Экспорт результатов опросов  в Excel, PDF</span>
              <img src={mark} alt="icon" className="w-5"></img>
            </div>
            <div className="flex text-white text-sm mt-4 justify-center items-center">
              <span className="w-44 mr-auto">Оплата от юридических лиц</span>
              <img src={mark} alt="icon" className="w-5"></img>
            </div>
          </div>
        </div>
    </div>
  </div>
) : isActive ? (
  <div className="tariffs-slider tablet:relative tablet:z-20 tablet:-ml-5 font-light text-xl mobile:-ml-[87px] desktop:ml-96">
    <Slider {...settings}>
      {
        tariffs?.map((tariff, index) => (
        <div
          key={index}
          className="slider-container-1 mobile:ml-24 w-[320px] bg-gradient-to-b from-cardGradient to-bg-darkPurple rounded-xl mt-10 mr-10 mb-10 border-cardBorder border flex flex-col items-center justify-center p-[30px] relative z-30"
        >
          <h1 className="text-4xl text-center font-normal font-fututraFont text-white mt-5">
            {tariff.name}
          </h1>
          <div className="pt-[30px] flex flex-col gap-6 items-center relative z-10 mb-10 mt-7">
            <hr className="w-[260px] mobile:w-[280px] tablet:w-[414px] h-[1px] text-white" />
            <span className="text-white text-2xl text-center">
              {tariff["html_description"]?.subtitle}
            </span>
            <hr className="w-[260px] mobile:w-[280px] tablet:w-[414px] h-[1px] text-white" />
          </div>
          <ul className="text-white pt-[30px] flex flex-col gap-4 list-disc pl-[30px] justify-self-center">
            {
              tariff["html_description"]?.tariffPoints.map((feature, i) => (
              <li key={i} className="mb-2">
                {feature}
              </li>
            ))
            }
          </ul>
          <div className="flex flex-col items-center mt-4">
            <span className="text-2xl text-white pt-14 pb-8">
              {tariff?.["price_month_single"]} ₽ / месяц
            </span>
            <button className="w-[270px] h-[50px] rounded-[7px] bg-cardBtnPurple cursor-pointer">
              <span className="text-white text-[18px]">Выбрать</span>
            </button>
          </div>
        </div>
      ))}
    </Slider>
  </div>
) : (
  <div className="laptop:flex">
    {
      tariffs?.map((tariff, index) => (
      <div
        key={index}
        className="slider-container-1 mobile:ml-24 w-[320px] laptop:w-[356px] desktop:w-[418px] desktop:mr-[95px] laptop:m-[15px] bg-gradient-to-b from-cardGradient to-bg-darkPurple rounded-[20px] mt-10 mr-10 mb-10 border-cardBorder border flex flex-col items-center justify-center p-[30px] relative z-30"
      >
        <h1 className="text-[45px] text-center font-normal font-fututraFont text-white mt-5">
          {tariff.name}
        </h1>
        <div className="pt-[30px] flex flex-col gap-6 items-center relative z-10 mb-10 mt-7 h-[83px] justify-center">
          <hr className="w-[260px] mobile:w-[280px] desktop:w-[335px] h-[1px] text-white" />
          <span className="text-white laptop:text-[20px] desktop:text-[24px] text-center">
            {tariff["html_description"].subtitle}
          </span>
          <hr className="w-[260px] mobile:w-[280px] desktop:w-[335px] h-[1px] text-white" />
        </div>
        <ul className="text-white pt-[60px] flex flex-col gap-4 list-disc pl-[30px] justify-self-center laptop:text-[20px] desktop:text-[24px]">
          {
            tariff["html_description"]?.tariffPoints.map((feature, i) => (
            <li key={i} className="mb-1">
              {feature}
            </li>
          ))
          }
        </ul>
        <div className="flex flex-col items-center mt-4 mt-auto">
          <span className="text-2xl text-white pt-14 pb-8">
            {tariff?.["price_month_single"]} ₽ / месяц
          </span>
          <button className="desktop:w-[300px] desktop:h-[60px] laptop:w-[270px] laptop:h-[50px] rounded-[20px] bg-cardBtnPurple cursor-pointer">
            <span className="text-white desktop:text-[32px] laptop:text-[24px]">Выбрать</span>
          </button>
        </div>
      </div>
    ))}
  </div>
)}
      </>
        {areCardsExpanded && screenType === 'mobile' ? <span className="text-[16px] -mt-8 text-white text-base relative z-20 desktop:p-12 desktop:block desktop:text-lg desktop:-mt-[40px] laptop:ml-10 tablet:hidden mobile:text-[10px] mobile:ml-7 mobile:absolute">
          *Оплата безналичным переводом для юридических лиц и ИП
        </span> : <span className="desktop:text-[24px] text-white relative z-20 desktop:p-12 desktop:block desktop:text-lg desktop:-mt-[40px] desktop:-ml-8 laptop:ml-10 tablet:hidden mobile:text-[10px] mobile:-mt-7 mobile:ml-7 mobile:absolute">
          *Оплата безналичным переводом для юридических лиц и ИП
        </span> }

        <div className="desktop:w-[1154px] tablet:w-[600px] desktop:h-[800px] tablet:h-[900px] mobile:hidden absolute bg-gradient-to-t from-purpleShadow to-bg-darkPurple opacity-30 blur-2xl rounded-full z-0 -mt-[500px] -ml-[300px] tablet:z-10 tablet:ml-[40px]"></div>
      </div>
          <div className="bg-radial-gradient desktop:w-[1000px] h-[800px] blur-3xl rounded-full absolute z-0 -ml-[800px] mt-[1100px] opacity-65"></div>
    </div>
  );
};

export default Tariffs;