import FAQTitle from "./FAQTitle/FAQTitle";
import AccordionList from "./Accordion/AccordionList/Accordion";

const Faq = ({ questions }) => {

	return (

		<div className="bg-myBg relative z-10">

			<div className="total-settings sm:pt-[74px] md:pt-[120px] lg:pt-[100px] pb-1 -mb-1">

				<FAQTitle />

				<AccordionList questions={questions}/>

			</div>

		</div>
		
	);
}

export default Faq;
