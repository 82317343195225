export const fetchLandingData = async (apiUrl, token) => {
    try {
        const res = await fetch(apiUrl, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })

        if (!res.ok) {
            return { error: "Произошла ошибка" }
        }

        const landingData = await res.json();

        return { landingData };
    } catch(error) {
        return { error }
    }
}