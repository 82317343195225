import closeBtn from '../../../assets/footer/modalClose 6.svg';


const Modal = ({ visible, onClose, modalText }) => {

	const closeModal = (e) => {
		if (e.target.id === 'modal-container') onClose();
	};

	if (!visible) return null;


	return (

		<div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40 duration-500 transition-all' id='modal-container' onClick={closeModal}>

			<div className='sm:w-[320px] sm:h-[278px] md:w-[688px] md:h-[452px] xl:h-[716px] lg:w-[1060px] lg:h-[675px]  flex flex-col items-center justify-center relative bg-myBg border-[1px] border-solid border-primary-2 rounded-[30px] duration-500 transition-all'>

				<button className=' absolute sm:top-5 sm:right-5 md:top-10 md:right-10 lg:top-[65px] lg:right-[55px] ' onClick={onClose}>
					<img src={closeBtn} />
				</button>

				<span className='sm:text-[22px] md:text-[40px] lg:text-6xl text-white-1 text-center' dangerouslySetInnerHTML={{ __html: modalText }}>

				</span>

			</div>

		</div>
	)
}

export default Modal;
