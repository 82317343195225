import React from "react";

const PrevArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <div
      className={"custom-prev"}
      style={{
        ...style,
        fontSize: "46px",
        color: "white",
        position: "absolute",
        marginTop: "410px",
        marginLeft: "60px",
        zIndex: "10",
      }}
      onClick={onClick}
    >
      <span>&lt;</span>
    </div>
  );
};

export default PrevArrow;
