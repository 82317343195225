import { AccordionItem, AccordionBody, AccordionHeader, } from "react-headless-accordion";

const AccordionUnit = ({ question, body, id }) => {

	return (

		<AccordionItem className={`accordion-item cursor-pointer `} key={id}>

			{({ open }) => (

				<>
					<AccordionHeader className=' accordion-item flex flex-col justify-between'>

						<div className='sm:pr-[16px] sm:rounded-lg sm:p-[10px] md:p-0 md:py-[25px] md:pl-[30px] md:pr-10 w-[100%] flex justify-between items-center'>

							<h3 className={`accordion-question ${open ? 'accordion-active' : ''}`}>{question}</h3>

							<div className={`accordion-arrow ${open ? 'rotate-180' : 'rotate-0'} flex items-center duration-500`}  >

								<svg className='sm:w-5 sm:fill-white md:w-8 md:h-[14px] md:fill-gray-1 lg:w-10 lg:h-[25px]' viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M0.459011 0.921314C-0.123042 1.51503 -0.161391 2.51245 0.373356 3.1491L18.7274 25.0009C19.204 25.5684 19.975 25.6577 20.5491 25.2501C20.6871 25.1722 20.8158 25.0681 20.9289 24.9377L39.6038 3.4326C40.1499 2.80381 40.128 1.80419 39.555 1.19989C38.982 0.595591 38.0748 0.615439 37.5288 1.24423L19.8177 21.6395L2.48116 0.999063C1.94641 0.362406 1.04106 0.327597 0.459011 0.921314Z" fill="" />
								</svg>

							</div>

						</div>

						<AccordionBody >

							<div className='sm:pr-[30px] sm:p-[10px] md:p-0 md:py-[25px] md:pl-[30px] md:pr-10 accordion-body ' dangerouslySetInnerHTML={{ __html: body }}></div>

						</AccordionBody>

					</AccordionHeader>
				</>
			)}

		</AccordionItem>

	)
}

export default AccordionUnit;
