import FooterList from './FooterList/FooterList';

const Footer = () => {

	return (
		<div className=' bg-gradient-to-l from-[#2b273b] to-[#190f40]'>

			<div className="total-settings  sm:pt-8 sm:pb-[102px] md:pb-10 lg:pt-12 lg:pb-[73px]">

				<FooterList />

				<div className=' sm:text-base md:text-sm  md:flex gap-x-1 xl:text-lg text-white-1'>

					<p>	© 2007 - 2024</p>
					<p>	Проект аналитического центра НАФИ.</p>
					<p>	Все права защищены.	</p></div>

			</div>

		</div>
	);
}

export default Footer;
