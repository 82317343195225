export const postFormData = async (apiUrl, token, userFormData) => {
    try {
        const res = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(userFormData)
        })

        const formRes = await res.json();

        return { status: formRes.status };
    } catch (error) {
        return { error };
    }
}