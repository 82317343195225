import firstSlide from '../../../assets/main/imgs/analitycs.svg';
import secondSlide from '../../../assets/main/imgs/carousel-pen.svg';
import thirdSlide from '../../../assets/main/imgs/carousel-film.svg';

export const carouselData = [
	{
		id: 1,
		img: `${firstSlide}`,
		title: `Аналитика от НАФИ <br /> в вашем личном кабинете`
	},
	{
		id: 2,
		img: `${secondSlide}`,
		title: `Оформление в соответствии <br/> с вашим брендбуком`
	},
	{
		id: 3,
		img: `${thirdSlide}`,
		title: `Добавление интерактива <br/> для сбора статистики`
	},

]
