import React from "react";

const TariffNextArrow = (props) => {
  const { style, onClick } = props;

  return (
    
    <div
      className={"tafif-custom-next"}
      style={{
        ...style,
        fontSize: "46px",
        color: "white",
        marginLeft: "720px",
        marginTop: "-468px",
        position: "absolute",
      }}
      onClick={onClick}
    >
      <span>&gt;</span>
    </div>
  );
};

export default TariffNextArrow;
