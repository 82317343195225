import Top from "./Top/Top";
import Carousel from "./Carousel/Carousel";
import Analytics from "./Analytics/Analytics";
import Constructor from "./Constructor/Constructor";
import Instruments from "./Instruments/Instruments";

import bigNet from "../../assets/main/imgs/IMG_Большая сетка.png";


const Main = () => {
	return (
		<div className="mobile:px-5  tablet:px-10  laptop:px-[85px] laptop:gap-y-[120px] desktop:px-[120px] relative container m-auto   font-fututraFont ">

			<Top />

			<Instruments />

			<div className="mobile:pt-[74px]  desktop:mt-[135px] desktop:text-[60px] flex flex-col mobile:items-center mobile:gap-y-[74px] laptop:gap-y-10 w-[100%] font-light text-white text-left ">

				<Carousel />

			</div>

			<div>
				<img
					src={bigNet}
					className="mobile:hidden laptop:block absolute laptop:top-[40%] laptop:h-[77%] desktop:t-[45%] desktop:h-[80%]  bg-cover z-0 w-[100%] "
				/>
			</div>
			{/* тени */}

			<div className="flex">

				<div className="bg-radial-gradient laptop:absolute desktop:-ml-[100px] desktop:-mt-[150px] z-40 desktop:w-[296px] desktop:h-[424px] rounded-full blur-2xl opacity-50"></div>

				<div className="bg-radial-gradient-turq laptop:absolute z-40 desktop:w-[439px] desktop:h-[559px] rounded-l-full blur-[80px] opacity-30 rotate-45 desktop:right-[60px] desktop:-mt-[100px]"></div>

			</div>

			<Analytics />

			{/* тень */}

			<div className="desktop:w-[340px] desktop:h-[340px] blur-[80px] rounded-l-full bg-purpleShadow opacity-40 desktop:ml-[1570px] desktop:absolute desktop:-mt-[650px]"></div>

			<div className="bg-radial-gradient-orange desktop:w-[460px] desktop:h-[460px] blur-[80px] rounded-l-full opacity-30 desktop:ml-[1450px] desktop:absolute desktop:-mt-[350px]"></div>

			<div className=" bg-radial-gradient-turq desktop:w-[540px] desktop:h-[540px] blur-2xl rounded-full opacity-10 desktop:absolute desktop:-ml-28 desktop:-mt-[650px]"></div>

			<div className="bg-radial-gradient-brown desktop:w-[660px] desktop:h-[460px] blur-3xl rounded-full opacity-20 desktop:ml-56 desktop:absolute desktop:-mt-[350px]"></div>

			<Constructor />

			<div className="bg-radial-gradient-brown desktop:w-[830px] desktop:h-[681px] blur-3xl rounded-full opacity-20 desktop:ml-[68rem] desktop:absolute desktop:-mt-[850px]"></div>

		</div>
	);
};

export default Main;
