import { useEffect, useState } from "react";

const FAQTitle = () => {

	const [isMobile, setIsMobile] = useState(false);
	
	const DEFAULT_MOBILE_BREAKPOINT = 640;

	const text1 = 'FAQ';
	const text2 = 'Часто задаваемые вопросы';

	const useIsMobile = (breakpoint = DEFAULT_MOBILE_BREAKPOINT) => {


		useEffect(() => {

			const onResize = () => {
				
				setIsMobile(window.innerWidth < breakpoint);
			};

			window.addEventListener("resize", onResize);

			onResize();

			return () => window.removeEventListener("resize", onResize);

		}, []);

		return isMobile;

	};

	return (

		<div className="container ">

			<h2 className="title text-start">{useIsMobile() ? text1 : text2}</h2>

		</div>
	)
}

export default FAQTitle;
