import analysis from "../../../assets/main/imgs/анализ.png";
import instuments from "../../../assets/main/imgs/инструменты2.png";
import constructorImg from "../../../assets/main/imgs/конструктор.png";


const Instruments = () => {

	return (
		<div className="mobile:hidden laptop:flex laptop:gap-x-[72px] laptop:pt-[100px]  desktop:h-[540px] desktop:gap-x-[160px] desktop:pb-[70px]  text-white  relative z-10">
			<div className="constructor-block">
				<span className="title-block">
					Анализ
				</span>
				<span className="text-block">
					Сбор данных для ваших презентаций
				</span>
				<img src={analysis} alt="diagram" className="laptop:w-52 desktop:w-[352px]" />
			</div>

			<div className="constructor-block">
				<span className="title-block">
					Инструменты
				</span>
				<span className="text-block">
					Живое общение со слушателями
				</span>
				<div className="desktop:flex desktop:gap-7">
					<img src={instuments} alt="diagram" className="desktop:w-[300px]" />
				</div>
			</div>

			<div className="constructor-block">
				<span className="title-block">
					Конструктор{" "}
				</span>
				<span className="text-block">
					Простая визуализация идей{" "}
				</span>
				<img src={constructorImg} alt="diagram" className="laptop:w-[175px] desktop:w-[361px]" />
			</div>
		</div>
	)
}

export default Instruments;
