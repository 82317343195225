import btnEnterIcon from '../../../../assets/main/imgs/profile-circle.svg';
import Logo768 from '../../../../assets/main/imgs/LogoMain768.png';

const LaptopNav = () => {

	return (

		<div className='mobile:hidden laptop:flex items-center laptop:pt-[41px] laptop:pb-[118px] justify-between relative z-30 w-[100%] '>
			<div className='mobile:hidden laptop:block'>
				<img src={Logo768} alt='Logo' />
			</div>
			<ul className="flex gap-x-[30px]  ">

				<li className="header-link">
					<a href="#!">О сервисе</a>
				</li>

				<li className="header-link">
					<a href="#!">Возможности</a>
				</li>

				<li className="header-link">
					<a href="##!">Тарифы</a>
				</li>

				<li className="header-link">
					<a href="##!">FAQ</a>
				</li>

				<li className="header-link">
					<a href="##!">Связаться с нами</a>
				</li>

			</ul>

			<a hrtef='#' className='laptop:w-[144px] laptop:h-[44px] laptop:rounded-[10px] flex items-center gap-[15px] px-[19px] bg-white3 cursor-pointer'>
				<img src={btnEnterIcon} alt='icon' />
				<span className='font-fututraFont text-[24px]'>Войти</span>
			</a>

		</div>
	)
}

export default LaptopNav;

