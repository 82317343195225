import blackEllipse from '../../../assets/footer/EllipseBlack.svg';
import ellipse1 from '../../../assets/footer/Ellipse 1.svg';
import ellipse2 from '../../../assets/footer/Ellipse2.svg';
import planet from '../../../assets/footer/planet.svg';
import ellipseGradient from '../../../assets/footer/EllipseGradient.svg';
import bottom from '../../../assets/footer/Иллюстрация.svg';



const FeedbackBg = () => {

	return (

		<div className='relative sm:h-[330px] md:w-[100%] md:h-[600px] lg:h-[825px] lg:w-1/2   xl:w-[100%] '>

			<img className='absolute sm:w-10 sm:top-4 sm:left-[52px] md:w-16 md:top-[70px] md:left-36 lg:top-[130px] lg:left-44' src={ellipse1} alt='Ellipse' />

			<img className='absolute sm:w-7 sm:left-12 md:w-12 md:top-0 md:left-36  lg:top-20 lg:left-44' src={blackEllipse} alt='Ellipse' />

			<img className='absolute sm:top-8 sm:left-14 sm:w-48 md:w-72 md:top-14 md:left-28 
			 lg:w-[210px] lg:top-20 lg:left-[150px]  max-w-80' src={planet} alt='Planet' />

			<img className='absolute sm:hidden md:block md:bottom-28 md:left-24  lg:top-72 lg:left-32' src={ellipse2} alt='Ellipse' />

			<img className='absolute sm:hidden md:block lg:top-24 lg:left-64' src={ellipseGradient} alt='Gradient' />

			<img className='absolute sm:w-[100%] sm:bottom-0 md:bottom-0 md:left-0 lg:bottom-60 lg:right-8 xl:bottom-1' src={bottom} alt='Image bottom' />

		</div>

	)
}

export default FeedbackBg;
