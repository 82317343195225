import { useState, useEffect } from "react";
import { carouselData } from "./CarouselData";
import rightArrow from '../../../assets/main/imgs/rightArrow.svg';
import leftArrow from '../../../assets/main/imgs/leftArrow.svg';



let count = 0;
let sliderInterval;

const CarouselItem = () => {

	const [currentIndex, setCurrentIndex] = useState(0);

	const data = carouselData;

	useEffect(() => {
		startSlider()
	}, []);

	const startSlider = () => {
		sliderInterval = setInterval(() => {
			handleOnNextClick()
		}, 3000)
	};


	const handleOnNextClick = () => {
		count = (count + 1) % data.length;
		setCurrentIndex(count)
	};

	const handleOnPrevClick = () => {
		const sliderLength = data.length;
		count = (currentIndex + sliderLength - 1) % sliderLength;
		setCurrentIndex(count)
	};

	return (

		<div className="flex relative">

			<div className="carousel-item" key={data[currentIndex].img}>

				<div className="desktop:mt-32 desktop:flex desktop:flex-col desktop:justify-center desktop:items-center">
					<img
						src={data[currentIndex].img}
						alt="icon"
						className="mobile:w-[100px] desktop:w-[250px] desktop:leading-normal relative desktop:z-20"
					/>
				</div>

				<span className="mobile:text-[20px] laptop:text-[28px]  desktop:text-[50px] desktop:max-w-[669px] relative z-20 text-center text-white" dangerouslySetInnerHTML={{ __html: data[currentIndex].title }}></span>
			</div>


			<div onClick={handleOnNextClick} className="absolute tablet:hidden top-[30%] w-8 -translate-x-0 translate-y-[50%] right-[-15%] text-2xl cursor-pointer text-white z-40">
				<img src={rightArrow} />
			</div>

			<div onClick={handleOnPrevClick} className="absolute tablet:hidden top-[47%] w-8 -translate-x-0 translate-y-[-50%] left-[-15%] text-2xl cursor-pointer text-white z-40">
				<img src={leftArrow} />
			</div>

		</div>
	)
}

export default CarouselItem;

