import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ComponentWithCaptcha } from '../Captcha/Captcha';
import { postFormData } from "../../../utils/postFormData";
import { apiUrl, token } from "../../../env";
import formBg from '../../../assets/footer/formBg.svg';


const FeedBackForm = ({ isModalShow }) => {

	const [verified, setVerified] = useState(false);
	const [resetCaptcha, setResetCaptcha] = useState(0);

	const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm(
		{
			defaultValues: {
				company: '',
				email: '',
				textarea: '',
				tel: '',
			}
		}
	)

	const postFormDataHandler = async (apiUrl, token, userData) => {
		try {
			const res = await postFormData(apiUrl, token, userData);
			console.log(res.status)
		} catch (e) {
			console.log(e);
		}
	}


	const handleReset = () => {
		setResetCaptcha((prev) => prev + 1);
		setVerified(false)
	};

	function onSubmit(userData) {
		if (isValid && verified) {
			isModalShow(true, 'Форма отправлена')
			handleReset()
			reset()
			postFormDataHandler(`${apiUrl}/landing_feedback/`, token, {
				email: userData.email,
				phone: userData.tel,
				name: userData.name,
				company: userData.company,
				text: userData.textarea
			});
		}
	};

	return (

		<div >

			<h2 className='title '> У вас остались вопросы? <br /> Напишите нам </h2>

			<img src={formBg} className='sm:hidden lg:block xl:hidden absolute top-0 right-0' alt='background' />

			<form onSubmit={handleSubmit(onSubmit)} noValidate className='flex flex-col sm:gap-x-2 sm:pt-10 md:gap-[30px] lg:gap-5 relative z-10'>

				<label className='label'> <span className='label-span'> Имя</span>
					<input type='text' className='form-input outline-0 md:py-[26px] lg:py-[18px]' placeholder='Имя и фамилия' {...register('name', {
						required: 'Обязательно к заполнению'
					})}/>
				</label>

				<label className='label'> <span className='label-span'> Компания </span>
					<input type='text' className='form-input outline-0 md:py-[26px] lg:py-[18px]' placeholder='Компания' {...register('company',
						{
							required: 'Обязательно к заполнению',
							vavidate: {
								matchPattern: (v) =>
									/^[a-z0-9_-]{2,30}$/.test(v) || 'Введите название компании, от 2 до 30 символов.'
							}
						})
					} />
					{errors.company?.message && (
						<small className='error-text'>{errors.company.message}</small>
					)}
				</label>

				<label className='label'> <span className='label-span'> E-mail </span>
					<input type='email' {...register('email',
						{
							required: 'Обязательно к заполнению',
							validate: {
								matchPattern: (v) =>
									/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(v) || "Адрес электронной почты должен быть действительным",
							}
						}
					)} className='form-input outline-0 md:py-[26px] lg:py-[18px]' placeholder='E-mail' />
					{errors.email?.message && (
						<small className='error-text' >{errors.email.message}</small>
					)}
				</label>

				<label className='label'> <span className='label-span'> Напиши нам вопрос </span>
					<textarea type='textarea' className='form-input outline-0 md:h-[124px] md:py-[45px] lg:py-[18px] lg:h-[70px] xl:h-32 xl:p-[45px] resize-none  no-scrollbar' placeholder='Напишите вопрос' {...register('textarea', {
						required: 'Обязательно к заполнению'
					})} />
					{errors.textarea?.message && (
						<small className='error-text'>{errors.textarea.message}</small>
					)}
				</label>

				<label className='label'> <span className='label-span'> Контактный телефон </span>
					<input type='tel' {...register('tel',
						{
							required: 'Обязательно к заполнению',
							validate: {
								matchPattern: (v) =>
									/[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(v) || "Номер телефона должен быть действительным",
							}
						}
					)} className='form-input outline-0 sm:mb-4 md:mb-0 md:py-[26px] lg:py-[18px]' placeholder='Контактный телефон' />
					{errors.tel?.message && (
						<small className='error-text'>{errors.tel.message}</small>
					)}
				</label>

				<ComponentWithCaptcha
					setVerified={setVerified}
					resetCaptcha={resetCaptcha}
				/>

				<button type='submit' className='form-btn' disabled={!verified} >
					отправить
				</button>
			</form>
		</div>
	)
}

export default FeedBackForm;
