import React from 'react'
import eternityMark from '../assets/tariffs/eternityMark.svg';
import mark from '../assets/tariffs/mark.svg';

const Comparing = () => {
    return (
        <div className='bg-bg-darkPurple mt-28 mb-9 flex flex-col items-center justify-center'>
            <div className='flex desktop:ml-[620px] laptop:ml-[420px] tablet:ml-[374px] mb-[40px]'>
                <div className='flex flex-col text-center desktop:mr-[120px] laptop:mr-[120px] tablet:mr-[50px] '>
                    <span className='text-white font-normal desktop:text-[40px] laptop:text-[40px] tablet:text-lg' >Начальный</span>
                    <span className='text-white desktop:text-[18px] laptop:text-[18px] tablet:text-xs tablet:w-[96px] laptop:h-[42px] laptop:w-[191px] laptop:mt-5 laptop:text-center  tablet:h-[66px]'>Бесплатно</span>
                </div>
                <div className='flex flex-col text-center desktop:mr-[120px] laptop:mr-[120px] tablet:mr-[50px]'>
                    <span className='text-white desktop:text-[40px] laptop:text-[40px] tablet:text-lg'>Базовый</span>
                    <span className='text-white desktop:text-[18px] laptop:text-[18px]  laptop:h-[42px] laptop:w-[191px] laptop:mt-5 laptop:text-center tablet:text-xs tablet:w-[96px] tablet:h-[66px] max-w-72'>690 ₽ в месяц 550 ₽ в месяц при олате за год</span>
                </div>
                <div className='flex flex-col text-center desktop:mr-[120px] laptop:mr-[120px] tablet:mr-[50px]'>
                    <span className='text-white font-normal desktop:text-[40px] laptop:text-[40px] tablet:text-lg'>Премиум</span>
                    <span className='text-white desktop:text-[18px] laptop:text-[18px] laptop:h-[42px] laptop:w-[191px] laptop:mt-5 laptop:text-center tablet:text-xs tablet:w-[96px] tablet:h-[66px] max-w-72'>1400 ₽ в месяц <br /> 1120 ₽ в месяц при оплате за год</span>
                </div>
            </div>
            <div className='desktop:max-w-[1800px] laptop:max-w-[1114px] laptop:-ml-[100px] desktop:ml-0'>
                <span className='desktop:text-[28px] laptop:text-[28px] tablet:text-2xl text-cardBtnOrange font-normal ml-[65px] mb-[65px]'>Основное</span>
                <div className='flex ml-[65px]'>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[310px] laptop:mr-[170px] tablet:mr-[30px] tablet:w-[300px] tablet:whitespace-normal whitespace-nowrap'>Количество презентаций на аккаунте</span>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[310px] laptop:mr-[200px] tablet:mr-[90px] whitespace-nowrap'>до 3</span>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[310px] laptop:mr-[220px] tablet:mr-[90px] whitespace-nowrap'>до 5</span>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 whitespace-nowrap'>до 20</span>
                </div>
                <hr className="desktop:w-[1560px] laptop:w-[1144px] tablet:w-[688px] h-[1px] text-white relative z-20 ml-[60px] mt-[30px]" />
                <div className='flex ml-[65px]'>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[310px] laptop:mr-[180px] tablet:mr-[30px] tablet:w-[300px] tablet:whitespace-normal whitespace-nowrap'>Количество слайдов в одной презентации</span>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[300px] laptop:mr-[195px] tablet:mr-[90px] whitespace-nowrap'>до 5</span>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[300px] laptop:mr-[210px] tablet:mr-[80px] whitespace-nowrap'>до 10</span>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 whitespace-nowrap'>до 50</span>
                </div>
                <hr className="desktop:w-[1560px] laptop:w-[1144px] tablet:w-[688px] h-[1px] text-white relative z-20 ml-[60px] mt-[30px]" />
                <div className='flex ml-[65px]'>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[320px] laptop:mr-[180px] tablet:mr-[50px] tablet:w-[300px] tablet:whitespace-normal whitespace-nowrap'>Экспорт презентации в PDF, PNG</span>
                    <img src={mark}  alt='icon' className='desktop:mr-[320px] laptop:mr-[220px] tablet:w-5 desktop:w-[30px] laptop:w-[30px] tablet:mr-[115px] '/>
                    <img src={mark} alt='icon' className='desktop:mr-[330px] laptop:mr-[230px]  tablet:w-5 desktop:w-[30px] laptop:w-[30px] tablet:mr-[125px]'/>
                    <img src={mark} alt='icon' className=' tablet:w-5 desktop:w-[30px] laptop:w-[30px]' />
                </div>
                <hr className="desktop:w-[1560px] laptop:w-[1144px] tablet:w-[688px] h-[1px] text-white relative z-20 ml-[60px] mt-[30px]" />

            </div>

            <div className='mt-[55px]'>
                <span className='desktop:text-[28px] laptop:text-[28px] tablet:text-2xl text-cardBtnOrange font-normal ml-[65px] mb-[65px]'>Интерактивы</span>
                <div className='flex ml-[65px]'>
                <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[310px] laptop:mr-[160px] whitespace-nowrap tablet:mr-[50px] max-w-[357px]'>Количество слайдов с интерактивными <br /> элементами в презентации
                </span>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[310px] laptop:mr-[250px] whitespace-nowrap tablet:mr-[100px]'>1</span>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[310px] laptop:mr-[200px] whitespace-nowrap tablet:mr-[90px]'>до 3</span>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 whitespace-nowrap'>до 10</span>
                </div>
                <hr className="desktop:w-[1560px] laptop:w-[1144px] tablet:w-[688px] h-[1px] text-white relative z-20 ml-[60px] mt-[30px]" />
                <div className='flex ml-[65px]'>
                <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[340px] laptop:mr-[190px] laptop:mt-5 whitespace-nowrap tablet:mr-[80px]'>Функция "мультиопрос" (несколько <br /> вопросов на одном слайде)
                </span>
                    <hr className='h-[2px] w-[24px] text-white ml-3 mt-8 desktop:mr-[310px] laptop:mr-[250px] laptop:mt-12 whitespace-nowrap tablet:mr-[95px] tablet:mt-11' />
                    <hr className='h-[2px] w-[24px] text-white ml-3 mt-8 desktop:mr-[320px] laptop:mr-[210px] laptop:mt-12 whitespace-nowrap tablet:mr-[95px] tablet:mt-11' />
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 whitespace-nowrap'><img src={mark} alt='icon' className=' tablet:w-5 desktop:w-[30px] laptop:w-[30px]' /></span>
                </div>
                <hr className="desktop:w-[1560px] laptop:w-[1144px] tablet:w-[688px] h-[1px] text-white relative z-20 ml-[60px] mt-[30px]" />
                <div className='flex ml-[65px]'>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[450px] laptop:mr-[300px] whitespace-nowrap max-w-[357px] tablet:mr-[170px]'>Количество учасников</span>
                    <img src={eternityMark}  alt='icon' className='desktop:mr-[310px] ml-3 mt-8 laptop:mr-[250px] tablet:mr-[105px]' />
                    <img src={eternityMark} alt='icon' className='desktop:mr-[330px] ml-3 mt-8 laptop:mr-[220px] tablet:mr-[95px]' />
                    <img src={eternityMark} alt='icon' className=' ml-3 mt-8 ' />
                </div>
                <hr className="desktop:w-[1560px] laptop:w-[1144px] tablet:w-[688px] h-[1px] text-white relative z-20 ml-[60px] mt-[30px]" />
                <div className='flex ml-[65px]'>
                <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[310px] laptop:mr-[160px] whitespace-nowrap tablet:mr-[50px]'>Возможность встраивания кода опроса <br /> на свой сайт
                </span>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[310px] laptop:mr-[250px] whitespace-nowrap tablet:mr-[100px]'>1</span>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[310px] laptop:mr-[200px] whitespace-nowrap tablet:mr-[90px]'>до 3</span>
                    <span className='desktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 whitespace-nowrap'>до 10</span>
                </div>
                <hr className="desktop:w-[1560px] laptop:w-[1144px] tablet:w-[688px] h-[1px] text-white relative z-20 ml-[60px] mt-[30px]" />
            </div>
            <div className='mt-[55px]'>
                <span className='desktop:text-[28px] laptop:text-[28px] tablet:text-2xl text-cardBtnOrange font-normal ml-[65px] mb-[65px] '>Дизайн</span>
                <div className='flex ml-[65px]'>
                <span className='desktop:text-[18px]  tabledesktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[310px] laptop:mr-[170px] whitespace-nowrap max-w-[357px] tablet:mr-[40px]'>Конструктор презентаций - стандартные <br /> шаблоны
                </span>
                    <img src={eternityMark}  alt='icon' className='desktop:mr-[315px] ml-3 mt-8 laptop:mr-[230px] tablet:mr-[100px]' />
                    <img src={eternityMark} alt='icon' className='desktop:mr-[330px] ml-3 mt-8 laptop:mr-[220px] tablet:mr-[100px]' />
                    <img src={eternityMark} alt='icon' className=' ml-3 mt-8 ' />
                </div>
                <hr className="desktop:w-[1560px] laptop:w-[1144px] tablet:w-[688px] h-[1px] text-white relative z-20 ml-[60px] mt-[30px]" />
                <div className='flex ml-[65px]'>
                <span className='desktop:text-[18px]  tabledesktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[520px] laptop:mr-[380px] whitespace-nowrap tablet:mr-[230px]'>Брендирование
                </span>
                    <hr className='h-[2px] w-[24px] text-white ml-3 desktop:mr-[300px] laptop:mr-[220px] laptop:mt-12 mt-8 whitespace-nowrap tablet:mr-[90px] tablet:mt-11' />
                    <img src={mark} alt='icon' className='ml-3 desktop:mr-[320px] laptop:mr-[200px] mt-8  tablet:w-5 desktop:w-[30px] laptop:w-[30px] tablet:mr-[90px]'/>
                    <img src={mark} alt='icon' className='ml-3 mt-8  tablet:w-5 desktop:w-[30px] laptop:w-[30px]' />
                </div>
                <hr className="desktop:w-[1560px] laptop:w-[1144px] tablet:w-[688px] h-[1px] text-white relative z-20 ml-[60px] mt-[30px]" />
            </div>

            <div className='mt-[55px]'>
                <span className='desktop:text-[28px] laptop:text-[28px] tablet:text-2xl text-cardBtnOrange font-normal ml-[65px] mb-[65px]'>Дополнительно</span>
                <div className='flex ml-[65px]'>
                <span className='desktop:text-[18px]  tabledesktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[320px] laptop:mr-[200px] whitespace-nowrap tablet:mr-[70px]'>Возможность использовать открытые <br />аналитические данные от НАФИ
                </span>
                    <hr className='h-[2px] w-[24px] text-white ml-3 desktop:mr-[300px] laptop:mr-[220px] laptop:mt-12 mt-8 whitespace-nowrap tablet:mr-[80px] tablet:mt-14' />
                    <img src={mark} alt='icon' className=' tablet:w-5 desktop:w-[30px] laptop:w-[30px] ml-3 desktop:mr-[320px] laptop:mr-[200px] mt-8 tablet:mr-[90px]'/>
                    <img src={mark} alt='icon' className=' tablet:w-5 desktop:w-[30px] laptop:w-[30px] ml-3 mt-8 whitespace-nowrap' />
                </div>
                <hr className="desktop:w-[1560px] laptop:w-[1144px] tablet:w-[688px] h-[1px] text-white relative z-20 ml-[60px] mt-[30px]" />
                <div className='flex ml-[65px]'>
                <span className='desktop:text-[18px] tabledesktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[390px] laptop:mr-[270px] whitespace-nowrap tablet:mr-[130px]'>Экспорт результатов опросов <br />  в Excel, PDF
                </span>
                    <img src={mark} alt='icon' className=' tablet:w-5 desktop:w-[30px] laptop:w-[30px] ml-3 mt-8 desktop:mr-[290px] laptop:mr-[210px] tablet:mr-[90px]' />
                    <img src={mark} alt='icon' className=' tablet:w-5 desktop:w-[30px] laptop:w-[30px] ml-3 mt-8 desktop:mr-[320px] laptop:mr-[200px] tablet:mr-[80px]' />
                    <img src={mark} alt='icon' className=' tablet:w-5 desktop:w-[30px] laptop:w-[30px] ml-3 mt-8' />
                </div>
                <hr className="desktop:w-[1560px] laptop:w-[1144px] tablet:w-[688px] h-[1px] text-white relative z-20 ml-[60px] mt-[30px]" />
                <div className='flex ml-[65px]'>
                <span className='desktop:text-[18px] tabledesktop:text-[18px] laptop:text-[18px] tablet:text-base text-white font-normal ml-3 mt-8 desktop:mr-[400px] laptop:mr-[280px] whitespace-nowrap tablet:mr-[140px]'>Оплата от юридических лиц
                </span>
                    <hr className='h-[2px] w-[24px] text-white ml-3 desktop:mr-[300px] laptop:mr-[210px] laptop:mt-12 mt-8 whitespace-nowrap tablet:mr-[80px] tablet:mt-11' />
                    <img src={mark} alt='icon' className=' tablet:w-5 desktop:w-[30px] laptop:w-[30px] ml-3 desktop:mr-[320px] mt-8 laptop:mr-[200px] tablet:mr-[80px]'/>
                    <img src={mark} alt='icon' className=' tablet:w-5 desktop:w-[30px] laptop:w-[30px] ml-3 mt-8'/>
                </div>
                <hr className="desktop:w-[1560px] laptop:w-[1144px] tablet:w-[688px] h-[1px] text-white relative z-20 ml-[60px] mt-[30px]" />
            </div>
        </div>
    )
}

export default Comparing;