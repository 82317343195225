/* eslint-disable no-undef */
import { useState, useCallback } from 'react';
import { SmartCaptcha } from '@yandex/smart-captcha';

export const ComponentWithCaptcha = ({ resetCaptcha, setVerified, }) => {

	const [token, setToken] = useState('');

	const [status, setStatus] = useState('hidden');

	const handleSuccess = useCallback((token) => {
		setStatus('success');
		setToken(token);
		setVerified(true)
	}, []);


	return (
		<SmartCaptcha
			key={resetCaptcha}
			sitekey="ysc1_v8Gh74WpzZXK8aLG4VOPIc3NLGZaQKHncixBSAjS6e4f6ee7"
			onSuccess={handleSuccess}
		/>
	);
};



