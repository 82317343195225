import { useState } from 'react';
import QRCode from '../../../../assets/main/imgs/QR Code.png';
import Logo768 from '../../../../assets/main/imgs/LogoMain768.png';
import Logo from '../../../../assets/main/imgs/headerLogo.svg';
import MobileNav from './MobileNav';
import LaptopNav from './LaptopNav';

const HeaderMenu = () => {

	const [isNavOpen, setIsnavOpen] = useState(false)

	return (<>

		<div className='tablet:hidden  px-[60px] pt-1 flex items-center justify-between gap-x-3'>

			<div className='flex items-center gap-x-1'>

				<img src={QRCode} alt='QRcode' />

				<span className='text-[12px] text-white font-thin'>Вы участник?</span>

			</div>
			<button className='w-[111px] h-6 bg-cardBtnOrange text-white text-[12px] font-thin rounded-[12px]'>Присоединиться</button>

		</div>

		<div className='mt-[22px] mobile:px-[20px] tablet:px-10  laptop:px-[85px] desktop:px-[120px] flex justify-between  relative'>


			<div>
				<img src={Logo} alt='LOGO' className='tablet:hidden' />
				<img src={Logo768} alt='LOGO' className='mobile:hidden  tablet:block laptop:hidden' />
			</div>

			<div className='laptop:hidden space-y-2' onClick={() => setIsnavOpen((prev) => !prev)}>
				<span className='block h-0.5 tablet:w-12 mobile:w-8 animate-pulse bg-cardBtnOrange'></span>
				<span className='block h-0.5 tablet:w-12 mobile:w-8 animate-pulse bg-cardBtnOrange'></span>
				<span className='block h-0.5 tablet:w-12 mobile:w-8 animate-pulse bg-cardBtnOrange'></span>
			</div>

			<div className={isNavOpen ? 'showMenuNav' : 'hideMenuNav'}>

				<div className='absolute mobile:top-[10px] mobile:right-[-10px] tablet:top-[24px] laptop:hidden tablet:right-0 px-8 py-8'
					onClick={() => setIsnavOpen(false)}>
					<svg
						className="h-8 w-8  text-cardBtnOrange"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<line x1="18" y1="6" x2="6" y2="18" />
						<line x1="6" y1="6" x2="18" y2="18" />
					</svg>
				</div>

				<MobileNav />

			</div>

				<LaptopNav />

		</div>
	</>

	)
}

export default HeaderMenu;
