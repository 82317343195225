import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReviewsCard from "./ReviewsCard";

const Reviews = ({ reviews, nextArrow, areCardsExpanded }) => {
  const [isActive, setIsActive] = useState(window.innerWidth <= 1281);

  useEffect(() => {
    const handleResize = () => {
      setIsActive(window.innerWidth <= 1280);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    prevArrow: null,
    nextArrow: nextArrow,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 2048,
        settings: "unslick",
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-bg-darkPurple max-w-[1678px] desktop:ml-[100px] font-fututraFont desktop:mt-[100px] tablet:ml-0 m-auto tablet:overflow-hidden mobile:max-w-[360px] tablet:max-w-[790px] laptop:max-w-[1280px] desktop:max-w-[1920px] mobile:ml-8 laptop:overflow-hidden relative mobile:mt-[350px] tablet:mt-[120px] laptop:mt-0">
      <>
      {areCardsExpanded ? (
      <div className=" hidden">
        <h1 className="text-white w-36 pt-[60px] text-[36px]  desktop:text-[60px] desktop:ml-6 mobile:text-2xl font-light ml-[30px] mobile:-ml-4 mb-12 relative tablet:z-20 laptop:relative laptop:z-20">
          Отзывы
        </h1>
            <div className="reviews-slider laptop:max-w-[1140px] tablet:max-w-[750px] mobile:max-w-[390px] relative -mr-[120px] overflow-visible mobile:-ml-24 tablet:ml-0 laptop:relative laptop:z-20 mobile:z-20">
              <Slider {...settings}>
                {reviews?.map((review, index) => (
                    <ReviewsCard key={index} review={review} />
                ))}
              </Slider>
            </div>
            </div>
          ) : isActive ? (
            <div className=" reviews-slider-container desktop:ml-[20px] tablet:ml-[40px] desktop:mr-[120px] laptop:pr-0 tablet:pr-0">
        <h1 className="text-white w-36 pt-[60px] text-[40px]  desktop:text-4xl desktop:ml-6 mobile:text-3xl font-light ml-[30px] mobile:ml-0 mb-12 relative tablet:z-20 laptop:relative laptop:z-20">
          Отзывы
        </h1>
            <div className="reviews-slider laptop:max-w-[1340px] tablet:max-w-[750px] mobile:max-w-[390px] relative -mr-[120px] overflow-visible mobile:-ml-20 tablet:ml-0 laptop:relative laptop:z-20 mobile:z-20">
              <Slider {...settings}>
                {reviews?.map((review, index) => (
                    <ReviewsCard key={index} review={review} />
                ))}
              </Slider>
            </div>
            </div>
          ) : (
            <div className=" reviews-slider-container desktop:ml-[20px] tablet:ml-[40px] desktop:mr-[120px] laptop:pr-0 tablet:pr-0">
            
        <h1 className="text-white w-36 pt-[60px] desktop:text-[60px] desktop:ml-6 mobile:text-3xl font-light ml-[30px] mobile:ml-0 mb-12 relative tablet:z-20 laptop:relative laptop:z-20">
          Отзывы
        </h1>
            <div className="flex gap-3 ml-[20px] mr-[60px] desktop:w-[1678px]">
              {reviews?.map((review, index) => (
                <ReviewsCard key={index} review={review} />
              ))}
            </div>
            </div>
          )}
        </>
      

    </div>
  );
};

export default Reviews;
