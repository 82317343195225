import bgImg from '../../../assets/main/spheres.png';

const Top = () => {

	const handlerScrollUp = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}

	return (
		<div className="tablet:ml-0 desktop:flex  relative">
			<div className="mobile:pt-11  tablet:pt-[200px] laptop:pt-[54px] laptop:pb-[87px] desktop:pt-[100px]  flex flex-col relative z-100">

				<span className="mobile:text-[26px]  mobile:mb-[46px] mobile:w-[93%] tablet:mb-[60px] tablet:w-[32rem] tablet:text-[36px] laptop:mb-[44px] desktop:w-[47rem] desktop:text-[54px] desktop:mb-[54px]  leading-normal uppercase text-left text-white font-light inline-block relative z-10">
					Управляй своим временем с помощью технологий.
				</span>

				<span className="desktop:w-[52rem] desktop:text-4xl desktop:text-4xl tablet:w-[36rem] tablet:text-2xl mobile:w-[230px] mobile:text-xl desktop:text-[36px] text-left font-light inline-block text-white relative z-10">
					Доверьте нам подготовку к выступлению — получите уверенность
					в презентации и вовлеченность аудитории.
				</span>

				<button className="mobile:rounded-2xl mobile:h-9 mobile:mt-[270px]  tablet:w-[250px] tablet:h-[60px] tablet:text-xl  laptop:w-[297px] laptop:text-[22px] desktop:w-[385px] desktop:h-[76px] desktop:mt-52 uppercase desktop:rounded-[30px] desktop:text-2xl  desktop:mt-[200px] text-black  bg-cardBtnOrange  inline-block font-light hover:bg-btnHover hover:text-white transition-all relative z-10">
					Начать сейчас

				</button>
			</div>

			<div>
				<img src={bgImg} alt="background" className="absolute mobile:left-0 mobile:top-64 mobile:left-0 tablet:top-0  laptop:w-[85%] laptop:left-[200px] laptop:top-[-130px]  desktop:w-[72%] desktop:left-[690px]  z-0" />

				<button onClick={handlerScrollUp} className="mobile:hidden laptop:block fixed  bottom-10 right-20 z-50">
					<svg className="fill-cardBtnOrange hover:fill-btnHover transition-all" width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M32.5 0C26.0721 0 19.7886 1.90609 14.444 5.47724C9.09938 9.04838 4.93378 14.1242 2.47393 20.0628C0.0140817 26.0014 -0.629527 32.536 0.624493 38.8404C1.87851 45.1448 4.97384 50.9358 9.51904 55.481C14.0642 60.0262 19.8552 63.1215 26.1596 64.3755C32.464 65.6295 38.9986 64.9859 44.9372 62.5261C50.8758 60.0662 55.9516 55.9006 59.5228 50.556C63.0939 45.2114 65 38.9279 65 32.5C64.9909 23.8833 61.5639 15.622 55.4709 9.52908C49.378 3.43612 41.1167 0.00909943 32.5 0ZM44.2688 31.7687C44.0366 32.0012 43.7609 32.1856 43.4574 32.3114C43.1539 32.4372 42.8285 32.502 42.5 32.502C42.1715 32.502 41.8461 32.4372 41.5427 32.3114C41.2392 32.1856 40.9634 32.0012 40.7313 31.7687L35 26.0344V45C35 45.663 34.7366 46.2989 34.2678 46.7678C33.7989 47.2366 33.163 47.5 32.5 47.5C31.837 47.5 31.2011 47.2366 30.7322 46.7678C30.2634 46.2989 30 45.663 30 45V26.0344L24.2688 31.7687C23.7997 32.2378 23.1634 32.5014 22.5 32.5014C21.8366 32.5014 21.2004 32.2378 20.7313 31.7687C20.2622 31.2996 19.9986 30.6634 19.9986 30C19.9986 29.3366 20.2622 28.7003 20.7313 28.2312L30.7313 18.2312C30.9634 17.9988 31.2392 17.8144 31.5427 17.6886C31.8462 17.5628 32.1715 17.498 32.5 17.498C32.8285 17.498 33.1539 17.5628 33.4574 17.6886C33.7609 17.8144 34.0366 17.9988 34.2688 18.2312L44.2688 28.2312C44.5012 28.4634 44.6856 28.7391 44.8114 29.0426C44.9372 29.3461 45.002 29.6715 45.002 30C45.002 30.3285 44.9372 30.6539 44.8114 30.9573C44.6856 31.2608 44.5012 31.5366 44.2688 31.7687Z" fill="" />
					</svg>
				</button>

			</div>

		</div>
	)
}

export default Top
