import HeaderMenu from "./HeaderMoile/HeaderMenu/Headermenu";


const Header = () => {

	return (
		<div className="container mx-auto">
	
			<HeaderMenu />

		</div>
	);
}

export default Header;
