import { useEffect, useState } from "react";
import { fetchLandingData } from "./utils/fetchLandingData";
import { token, apiUrl } from "./env";
import { Route, Switch, Redirect } from 'react-router-dom';
import Faq from "./components/FAQBlock/FAQ";
import Feedback from "./components/FeedbackBlock/Feedback";
import Footer from "./components/Footer/Footer";
import Video from "./components/VideoBlock/Video";
import Tariffs from "./components/TariffsBlock/Tariffs";
import Reviews from "./components/ReviewsBlock/Reviews";
import NextArrow from "./components/ReviewsBlock/NextArrow";
import TariffNextArrow from "./components/TariffsBlock/TariffNextArrow";
import PrevArrow from "./components/TariffsBlock/PrevArrow";
import Header from './components/Header/Header'
import Main from "./components/Main/Main";
import Comparing from "./pages/Comparing";

const App = () => {
    const [landingData, setLandingData] = useState('');
    const [areCardsExpanded, setAreCardsExpanded] = useState(false);

    useEffect(() => {
        const getLandingData = async () => {
            const res = await fetchLandingData(`${apiUrl}/landing_data`, token);
            setLandingData(res.landingData);
            console.log(res.landingData.tariffs[0]["html_description"].tariffPoints)
        }

        getLandingData();
    }, []);

    return (
        <Switch>
            <Route path="/" exact>
                <div className="bg-bg-darkPurple max-w-[1920px] ">
                    <Header/>
                    <Main/>
                    <Video areCardsExpanded={areCardsExpanded}/>
                    <Tariffs tariffs={landingData.tariffs} tariffNextArrow={<TariffNextArrow/>} prevArrow={<PrevArrow/>}
                             areCardsExpanded={areCardsExpanded}
                             setAreCardsExpanded={setAreCardsExpanded}/>
                    <Reviews reviews={landingData.reviews} nextArrow={<NextArrow/>}
                             areCardsExpanded={areCardsExpanded}
                    />
                    <div>
                        <Faq questions={landingData.questions}/>
                        <Feedback/>
                        <Footer/>
                    </div>
                </div>
            </Route>
                <Route path="/comparing" exact>
                    <Comparing />
                </Route>
        </Switch>

    );
};

export default App;