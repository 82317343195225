import Logo from '../../../../assets/main/imgs/headerLogo.svg';
import tabLogo from '../../../../assets/main/imgs/Logo.768.png';

const MobileNav = () => {

	return (
		<>
			<div>
				<div className='mobile:pt-[50px] mobile:mb-[56px] tablet:pt-[30px] tablet:mb-[60px]  laptop:hidden'>
					<img src={Logo} alt='LOGO' className='tablet:hidden' />
					<img src={tabLogo} alt='Logo' className='mobile:hidden tablet:block laptop:hidden' />
				</div>

				<ul className="flex flex-col mobile:items-start tablet:items-center gap-y-[28px] ">

					<li className="header-link">
						<a href="#!">О сервисе</a>
					</li>

					<li className="header-link">
						<a href="#!">Возможности</a>
					</li>

					<li className="header-link">
						<a href="##!">Тарифы</a>
					</li>

					<li className="header-link">
						<a href="##!">FAQ</a>
					</li>

					<li className="header-link">
						<a href="##!">Связаться с нами</a>
					</li>
				</ul>

				<div className='flex flex-col tablet:items-center gap-[20px] mobile:mt-[240px] tablet:mt-[60px] laptop:hidden'>
					<a href='#' className='header-link-btn bg-cardBtnOrange  text-white  transition-all text-center'>Войти</a>
					<a href='#' className='header-link-btn  bg-white  text-black text-center'>Зарегистрироваться</a>
				</div>
			</div>
		</>
	)
}

export default MobileNav;
