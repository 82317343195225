import FooterInfo from '../FooterInfo/FooterInfo';
import logo from '../../../assets/footer/LOGO.png';

const FooterList = () => {

	return (
		<div>

			<div className='lg:flex justify-between gap-x-32 lg:mb-10 2xl:gap-x-[496px]'>

				<img className='sm:w-32 sm:h-6 md:w-48 md:h-8 lg:w-[220px] lg:h-10' src={logo} alt='Logo' />

				<FooterInfo />

			</div>

		</div>
	)
}

export default FooterList;
