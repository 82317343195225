import AccordionUnit from '../AccordionUnit/AccordionUnit';
import { Accordion } from "react-headless-accordion";
import faqShadow from '../../../../assets/footer/FAQ_shadow.svg';


const AccordionList = ({ questions }) => {

	return (
		<div className='sm:pt-5  md:pt-10 lg:pt-[100px]  lg:pb-10 xl:pb-0 flex flex-col gap-y-5'>

			<Accordion className='sm:gap-3 md:gap-[10px] lg:gap-5 xl:gap-[10px] flex flex-col' >

				<img src={faqShadow} className='sm:hidden lg:block xl:hidden opacity-[24%]  absolute -top-96 right-0 z-0' alt='shadow' />

				{
					questions?.map((question, index) => (

					<AccordionUnit
						key={index}
						question={question.question}
						body={question.answer}
					/>

				))
				}

			</Accordion>

		</div>
	)
}

export default AccordionList;

