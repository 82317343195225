import { useState } from "react";
import FeedBackForm from "./FeedBackForm/FeedBackForm";
import FeedbackBg from "./FeedbackBg/FeedbackBg";
import Modal from "./Modal/Modal";


const Feedback = () => {

	const [showModal, setShowModal] = useState(false);
	const [modalText, setModalText] = useState('');

	const handleOnClose = () => setShowModal(false);

	const showModalHandler = (isModalShow, modalText) => {
		setShowModal(isModalShow)
		setModalText(modalText)
	};

	return (

		<div className="bg-myBg relative ">

			<div className="total-settings sm:pt-[74px] md:pt-[120px] xl:pb-[72px] ">

				<div className="flex sm:flex-col sm:gap-[74px] sm:pb-[72px] md:gap-10 lg:flex-row xl:pb-[58px] xl:gap-6">

					<FeedbackBg />

					<FeedBackForm
						isModalShow={showModalHandler}
					/>

					<Modal
						onClose={handleOnClose}
						visible={showModal}
						modalText={modalText} />

				</div>

			</div>

		</div>
	);
}

export default Feedback;

