const FooterInfo = () => {

	return (

		<div className='flex sm:flex-col sm:gap-y-8 sm:mt-[54px] sm:mb-9 md:flex-row md:gap-y-0 md:gap-x-[55px] md:my-[50px] lg:mt-0  lg:gap-x-16 xl:gap-x-28 '>

			<div className='md:min-w-[235px] xl:min-w-[292px]'>

				<h3 className='footer-title'>Компания</h3>

				<ul className='flex flex-col gap-1'>

					<li>
						<a href='#' className='footer-link'> О сервисе</a>
					</li>

					<li>
						<a href='#' className='footer-link'> Пользовательское соглашение</a>
					</li>

					<li>
						<a href='#' className='footer-link'> Политика конфиденциальности </a>
					</li>

				</ul>

			</div>

			<div className='md:min-w-[130px] xl:min-w-40'>

				<h3 className='footer-title'>Информация</h3>

				<ul className='flex flex-col gap-1'>

					<li>
						<a href='#' className='footer-link'>FAQ</a>
					</li>

					<li>
						<a href='#' className='footer-link'>Связаться в нами</a>
					</li>
					
				</ul>

			</div>

			<div className='md:min-w-[220px] pr-6' >

				<h3 className='footer-title'>Контакты</h3>

				<ul className='flex flex-col gap-[10px]'>

					<li>
						<a href='#' className='footer-link'>+7 (495) 982-50-27</a>
					</li>

					<li>
						<a href='#' className='footer-link  inline-block'>
							125047, Россия, г. Москва, ул. 1-я Брестская, д. 29 <br />
							(БЦ «Капитал Тауэр»)
						</a>
					</li>

					<li>
						<a href='#' className='footer-link'>ilimetr@nafi.ru</a>
					</li>

				</ul>

			</div>
		</div>
	)
}

export default FooterInfo;

